import React from "react";
import { useNavigate } from "react-router-dom";
import myImage from "../static/img/data.jpeg";
import "../static/css/publications.css";
import { useEffect, useState } from "react";

const Publications = () => {
  const [publications, setPublications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://skpbackend.quindltechnologies.com/categories")
      .then((res) => res.json())
      .then((data) => {
        const formattedData = data.map((category) => ({
          title: category.name,
          imgSrc: category.image || myImage,
          description: category.description,
          date: new Date().toISOString().split("T")[0],
          link: "/publication-detail",
          categoryId: category.id,
        }));
        setPublications(formattedData);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleReadMore = (publication) => {
    navigate("/publication-detail", {
      state: {
        publication,
        allPublications: publications,
      },
    });
  };

  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <>
      <div className="publications">
        <div className="con">
          <h2>Our Publications</h2>
          <div className="con-grid">
            {publications.map((publication, index) => (
              <div key={index} className="con-item">
                <img
                  className="con-img"
                  src={publication.imgSrc}
                  alt={publication.title}
                />
                <div className="con-content">
                  <div className="con-txt-1">
                    <p>{publication.title}</p>
                  </div>
                  <div className="con-txt-2">
                    <p>{truncateText(publication.description)}</p>
                  </div>
                  <button
                    className="read-more-btn"
                    onClick={() => handleReadMore(publication)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;
