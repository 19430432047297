import React from "react";
import img1 from "../static/img/20221.jpeg";
import img2 from "../static/img/20222.jpeg";
import img3 from "../static/img/20223.jpeg";
import img4 from "../static/img/20224.jpeg";
import img5 from "../static/img/20225.jpeg";
import img6 from "../static/img/20226.jpeg";
import { Link } from "react-router-dom";
import "../static/css/Year.css";

const box = [
  { imgSrc: img1 },
  { imgSrc: img2 },
  { imgSrc: img3 },
  { imgSrc: img4 },
  { imgSrc: img5 },
  { imgSrc: img6 },
];

const Publications = () => {
  return (
    <div className="year-container">
      <div className="year-box">
        <div className="year-main">
          <div className="year-title">
            <h2>Moments to Remember</h2>
            <hr className="year-line" />
          </div>
          <div className="year-back">
            <Link to="/gallery">
              <p>Back</p>
            </Link>
          </div>
        </div>

        <div className="year-grid">
          {box.map((item, index) => (
            <div key={index} className="year-item">
              <img className="year-img" src={item.imgSrc} alt="year" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Publications;
