import React, { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ServiceHeader from "../components/ServiceHeader";
import events_img from "../static/img/Events.jpg";
import "../static/css/Events.css";

function Events() {
  const [eventsData, setEventsData] = useState({});
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const months = [];
  for (let i = 0; i < 5; i++) {
    const monthIndex = (currentMonth + i) % 12;
    const year = currentYear + Math.floor((currentMonth + i) / 12);
    months.push({
      name: new Date(year, monthIndex).toLocaleString("default", {
        month: "long",
      }),
      year: year,
    });
  }

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://skpbackend.quindltechnologies.com/schedule"
        );
        const data = await response.json();

        const futureEvents = data.filter(
          (event) => new Date(event.date) >= new Date().setHours(0, 0, 0, 0)
        );

        const groupedEvents = futureEvents.reduce((acc, event) => {
          const eventDate = new Date(event.date);
          const monthYear = `${eventDate.toLocaleString("default", {
            month: "long",
          })} ${eventDate.getFullYear()}`;

          if (!acc[monthYear]) {
            acc[monthYear] = [];
          }
          acc[monthYear].push(event);
          return acc;
        }, {});

        Object.keys(groupedEvents).forEach((monthYear) => {
          groupedEvents[monthYear].sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
        });

        setEventsData(groupedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <>
      <Nav />
      <ServiceHeader
        image={events_img}
        title="Upcoming Events"
        description="Stay updated with our latest events and programs"
      />
      <div className="events-container">
        {months.map(({ name, year }) => (
          <div key={`${name}-${year}`} className="month-section">
            <h2>{`${name} ${year}`}</h2>
            <div className="events-grid">
              {eventsData[`${name} ${year}`]?.map((event, index) => (
                <div key={index} className="event-card">
                  <div className="event-date">
                    {new Date(event.date).getDate()}
                    <span>
                      {new Date(event.date).toLocaleString("default", {
                        month: "short",
                      })}
                    </span>
                  </div>
                  <div className="event-details">
                    <h3>{event.event}</h3>
                    <p className="event-time">
                      {new Date(`1970-01-01T${event.time}`).toLocaleTimeString(
                        "en-US",
                        { hour: "2-digit", minute: "2-digit", hour12: true }
                      )}
                    </p>
                    <p className="event-description">{event.description}</p>
                  </div>
                </div>
              )) || (
                <div className="no-events">
                  <p>No events scheduled</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Events;
