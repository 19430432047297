import React from "react";
import ServiceHeader from "../components/ServiceHeader";
import Year from "../components/Year";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function YearPage() {
  return (
    <>
      <Nav />
      <ServiceHeader title={"Moments to Remember"} description={""} />
      <Year />
      <Footer />
    </>
  );
}
export default YearPage;
