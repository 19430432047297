import React, { useState, useEffect } from "react";
import axios from "axios";

const AwardImages = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get("https://skpbackend.quindltechnologies.com/gallery/award");
        const data = response.data;
        const formattedData = data.map(item => ({
          id: item.id,
          title: item.title,
          content: item.content,
          images: item.images.map((path, index) => ({
            id: index,
            src: `https://skpbackend.quindltechnologies.com${path}`,
            alt: `${item.title} - Image ${index + 1}`,
          })),
        }));
        setImages(formattedData);
      } catch (error) {
        console.error("Error fetching award images:", error);
      }
    };

    fetchImages();
  }, []);

  const openPopup = (image, item) => {
    setSelectedImage(image);
    setSelectedItem(item);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setSelectedItem(null);
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="image-container award-container">
      {images.map((item) => (
        <div key={item.id} className="image-wrapper">
          <div className="image-text">{item.title}</div>
          <img
            src={item.images[0].src}
            alt={item.images[0].alt}
            className="carousel-image"
            onClick={() => openPopup(item.images[0].src, item)}
          />
        </div>
      ))}

      {selectedImage && selectedItem && (
        <div className="full-img-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header">
              <h3>{selectedItem.title}</h3>
              <p>{selectedItem.content}</p>
            </div>
            <img
              src={selectedImage}
              alt="Selected"
              className="popup-main-image"
            />
            <div className="thumbnail-row">
              {selectedItem.images.map((img) => (
                <img
                  key={img.id}
                  src={img.src}
                  alt={img.alt}
                  className={`thumbnail ${selectedImage === img.src ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(img.src)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AwardImages;
