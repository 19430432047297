import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ServiceHeader from "../components/ServiceHeader";
import Connect from "../components/Connect";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import axios from "axios";
import img from "../static/img/team.png";
import "../static/css/MemberPage.css";

function MemberPage() {
  const { id } = useParams();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Fetching member with ID:", id);
    axios
      .get(`https://skpbackend.quindltechnologies.com/team/members/${id}`)
      .then((response) => {
        const fetchedMember = response.data;
        if (typeof fetchedMember.specializations === "string") {
          fetchedMember.specializations = JSON.parse(
            fetchedMember.specializations
          );
        }
        setMember(fetchedMember);
        console.log("Fetched member data:", fetchedMember);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Error fetching member details:",
          error.response ? error.response.data : error.message
        );
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!member) {
    return <div>Member not found</div>;
  }

  return (
    <>
      <Nav />
      <ServiceHeader
        title={member.name}
        image={img}
        description={member.role}
      />
      <div className="member-content">
        <p className="member-bio">
          {member.description || "No description available."}
        </p>
        <h3 className="member-specializations-header">Specializations:</h3>
        <ul className="member-specializations-list">
          {Array.isArray(member.specializations) &&
          member.specializations.length > 0 ? (
            member.specializations.map((spec, index) => (
              <li key={index} className="member-specialization-item">
                {spec}
              </li>
            ))
          ) : (
            <li>No specializations available.</li>
          )}
        </ul>
      </div>
      <Connect />
      <Footer />
    </>
  );
}

export default MemberPage;
