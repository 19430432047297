import React,{useState,useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';
import "../static/css/Header.css";
import header1 from "../static/img/header1.jpg";
import header2 from "../static/img/header2.jpg";
import header3 from "../static/img/header3.png";
import header4 from "../static/img/header4.webp";

function Testimonials() {
  const image_list=[header1,header2,header3,header4];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setTimeout(() => {
      setIndex(index === image_list.length-1 ? 0 : index+1);
    }, 5000);
  });

  return (
    <> 
        <div className="header-container">
          <div className="Header-box">
            <div className="Header-content">
              <Carousel activeIndex={index} onSelect={handleSelect}>
                {image_list.map((image,index)=>(
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100 test-img"
                      src={image}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
    </>
  )
}

export default Testimonials;