import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TeamPage from "./pages/TeamPage";
import MemberPage from "./pages/MemberPage";
import ServicePage from "./pages/ServicePage";
import ProductPage from "./pages/ProductPage";
import PublicationPage from "./pages/PublicationPage";
import PublicationDetail from "./components/PublicationDetail";
import PublicationCardDetail from "./components/PublicationCardDetail";
import Events from "./pages/EventsPage";
import GalleryPage from "./pages/GalleryPage";
import YearPage from "./pages/YearPage";
import ContactUsPage from "./pages/ContactUsPage";
import UserLogin from "./pages/UserLogin";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage.jsx";
import TermsConditionsPage from "./components/TermsConditionsPage.jsx";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/publications" element={<PublicationPage />} />
        <Route path="/publication-detail" element={<PublicationDetail />} />
        <Route path="/publication/:id" element={<PublicationCardDetail />} />
        <Route path="/events" element={<Events />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/member/:id" element={<MemberPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="/year" element={<YearPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsConditionsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
