import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import "../static/css/publicationCardDetail.css";

const POPULAR_PUBLICATIONS_LIMIT = 5;

const PublicationCardDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { card, publicationTitle, allPublications = [] } = location.state || {};
  const [publicationDetail, setPublicationDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const popularPublications = React.useMemo(() => {
    if (!allPublications?.length) return [];
    const otherPublications = allPublications.filter(
      (pub) => pub.title !== card?.title
    );
    return otherPublications
      .sort(() => Math.random() - 0.5)
      .slice(0, POPULAR_PUBLICATIONS_LIMIT);
  }, [allPublications, card?.title]);

  useEffect(() => {
    if (!card?.id) {
      navigate("/");
      return;
    }

    setIsLoading(true);
    fetch(`https://skpbackend.quindltechnologies.com/publications/${card.id}`)
      .then(async (res) => {
        if (!res.ok) {
          const errorText = await res.text();
          console.error("Error response:", errorText);
          throw new Error("Failed to fetch publication");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Received publication data:", data);
        setPublicationDetail({
          ...data,
          image_url: data.image_url || card.imgSrc,
          title: data.title,
          description: data.description,
          date: data.date,
          content: data.content,
          category_name: data.category_name
        });
      })
      .catch((error) => {
        console.error("Error fetching publication detail:", error);
        setPublicationDetail(card);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [card?.id, navigate, card]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const displayData = publicationDetail || card;

  if (isLoading) {
    return (
      <>
        <Nav />
        <div className="article-detail-container">
          <div className="pop-sidebar">
            <h3>Popular Publications</h3>
            <div className="popular-publications">
              {popularPublications.length > 0 ? (
                popularPublications.map((pub) => (
                  <div
                    key={pub.title}
                    className="popular-pub-item"
                    onClick={() =>
                      navigate("/publication-detail", {
                        state: { publication: pub, allPublications },
                      })
                    }
                  >
                    <img src={pub.imgSrc} alt={pub.title} />
                    <h4>{pub.title}</h4>
                  </div>
                ))
              ) : (
                <p>No related publications available</p>
              )}
            </div>
          </div>
          <div className="article-layout">
            <button className="return-button" onClick={() => navigate(-1)}>
              Back to {publicationTitle || "Publications"}
            </button>
            <div className="article-container">
              <div className="primary-content">
                <div className="article-text">
                  <h1>{displayData.title}</h1>
                  <p className="date">{formatDate(displayData.date)}</p>
                  {displayData.category_name && (
                    <p className="category">Category: {displayData.category_name}</p>
                  )}
                  <div className="description-container">
                    <p className="description">{displayData.description}</p>
                  </div>
                </div>
                <div className="media-wrapper">
                  {displayData.image_url ? (
                    <img
                      src={displayData.image_url}
                      alt={displayData.title}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = "/fallback-image.jpg";
                        e.target.onerror = null;
                      }}
                    />
                  ) : (
                    <div className="empty-media">No image available</div>
                  )}
                </div>
              </div>
              <div className="article-body">
                <div 
                  dangerouslySetInnerHTML={{ __html: displayData.content }} 
                  className="rich-text-content"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Nav />
      <div className="article-detail-container">
        <div className="pop-sidebar">
          <h3>Popular Publications</h3>
          <div className="popular-publications">
            {popularPublications.length > 0 ? (
              popularPublications.map((pub) => (
                <div
                  key={pub.title}
                  className="popular-pub-item"
                  onClick={() =>
                    navigate("/publication-detail", {
                      state: { publication: pub, allPublications },
                    })
                  }
                >
                  <img src={pub.imgSrc} alt={pub.title} />
                  <h4>{pub.title}</h4>
                </div>
              ))
            ) : (
              <p>No related publications available</p>
            )}
          </div>
        </div>
        <div className="article-layout">
          <button className="return-button" onClick={() => navigate(-1)}>
            Back to {publicationTitle || "Publications"}
          </button>
          <div className="article-container">
            <div className="primary-content">
              <div className="article-text">
                <h1>{displayData.title}</h1>
                <p className="date">{formatDate(displayData.date)}</p>
                {displayData.category_name && (
                  <p className="category">Category: {displayData.category_name}</p>
                )}
                <div className="description-container">
                  <p className="description">{displayData.description}</p>
                </div>
              </div>
              <div className="media-wrapper">
                {displayData.image_url ? (
                  <img
                    src={displayData.image_url}
                    alt={displayData.title}
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = "/fallback-image.jpg";
                      e.target.onerror = null;
                    }}
                  />
                ) : (
                  <div className="empty-media">No image available</div>
                )}
              </div>
            </div>
            <div className="article-body">
              <div 
                dangerouslySetInnerHTML={{ __html: displayData.content }} 
                className="rich-text-content"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicationCardDetail;
