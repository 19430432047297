import React from "react";
import events_img from "../static/img/Events.jpg";
import ServiceHeader from "../components/ServiceHeader";
import Gallery from "../components/Gallery";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function GalleryPage() {
  return (
    <>
      <Nav />
      <ServiceHeader
        image={events_img}
        title="Gallery"
        description="Explore our extensive gallery of images"
      />
      <Gallery />
      <Footer />
    </>
  );
}
export default GalleryPage;
