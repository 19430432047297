import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import "../static/css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="dir">
        <div className="sec">
          <div className="sec1">
            <h3>Contact Us</h3>
            <p>
              CENTRAL OFFICE
              <br />
              849/2, 2nd floor, Mettupalayam Road,
              <br />
              Poo Market,
              <br />
              Coimbatore – 641002.
            </p>
            <br />
            <p>Phone: +91 9787597500</p>
            <p>Email: info@skpandco.in</p>
          </div>
          <div className="sec2">
            <h3>Quick Links</h3>
            <div className="icons">
              <a href="/">
                <FaLinkedin />
              </a>
              <a href="/">
                {" "}
                <FaInstagram />
              </a>
              <a href="/">
                <FaTwitter />
              </a>
              <a href="/">
                <FaFacebook />
              </a>
            </div>
          </div>
        </div>
        <div className="sec">
          <h3>Pages</h3>
          <ul>
            <li>
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/team">
                <span>Our Team</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services">
                <span>Services</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/publications">
                <span>Publications</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/events">
                <span>Events & Calendar</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/gallery">
                <span>Gallery</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/contact">
                <span>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="sec">
          <h3>Services</h3>
          <ul>
            <li>
              <Link to="/product/0">
                <span>Alternative Dispute Resolution</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/1">
                <span>Corporate Compliance Management</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/2">
                <span>Legal Management and Representation</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/3">
                <span>Transaction Advisory</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/4">
                <span>Due Diligence Audits</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/6">
                <span>Intellectual Property Rights</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/7">
                <span>Corporate Regulatory Risk Assessment</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/5">
                <span>Insolvency and Bankruptcy Practice</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/8">
                <span>Advisory Services</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/product/9">
                <span>Forex Advisory Services</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="social">
        <div className="social-text">
          <div className="s_align">
            <Link to="/privacy-policy">privacy policy</Link>
          </div>
          <div className="s_align">
            <p>© 2024 SKP & Co</p>
          </div>
          <div className="s_align">
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
