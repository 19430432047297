import React from "react";
import "../static/css/ProductIntro.css";

function ProductIntro({
  img,
  why,
  whyContent,
  benefits,
  benefitsContent,
  types,
  typesContent,
  elevate_head,
  elevate_content,
  footerimg,
}) {
  return (
    <div className="product-intro">
      <div className="benefits">
        <div className="benefits-container">
          <div className="benefits-header">
            <hr />
            <h4>{benefits}</h4>
          </div>
          <div className="benefits-box">
            {benefitsContent.map((item, index) => (
              <div className="benefits-content" key={index}>
                <div className="benefits-img">
                  <img src={item.img} alt="benefits" />
                </div>
                <div className="benefits-text">
                  <p>{item.title}</p>
                  <span>{item.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="Intro-container">
        <div className="Intro-box">
          <div className="details">
            <h4>{why}</h4>
            {whyContent.map((item, index) => (
              <>
                <div className="details-content" key={index}>
                  <div className="details-count">
                    <p>0{index + 1}</p>
                  </div>
                  <div className="details-text">
                    <p>{item.title}</p> <span>{item.description}</span>
                  </div>
                </div>
                <div className="details-line">
                  <div className="line"></div>
                </div>
              </>
            ))}
          </div>
          <div className="details-image">
            <img src={img} alt="Product" />
          </div>
        </div>
      </div>
      <div className="service-container">
        <div className="service-elevate">
          <div className="elevate-img">
            <img src={footerimg} alt="elevate" />
          </div>
          <div className="elevate-box">
            <h4>{elevate_head}</h4>
            <p>{elevate_content}</p>
          </div>
          <div className="elevate-years">
            <p>5+</p>
            <span>years of experience</span>
          </div>
        </div>
        <div className="service-include">
          <div className="service-include-box">
            <h4>{types}</h4>
            <>
              {typesContent.map((item, index) => (
                <div className="service-include-text" key={index}>
                  <div className="service-include-text-content">
                    {item.title ? (
                      <p>
                        <div className="service-include-circle">
                          <p></p>
                        </div>
                        {item.title}
                      </p>
                    ) : (
                      <div className="no-title-description">
                        <span>{item.description}</span>
                      </div>
                    )}

                    {item.description && item.title && (
                      <span>{item.description}</span>
                    )}
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductIntro;
