import React, { useState, useEffect } from "react";
import axios from "axios";

const AnniversaryImages = () => {
  const [imagesByYear, setImagesByYear] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://skpbackend.quindltechnologies.com/gallery/anniversary"
        );
        const data = response.data;
        const formattedData = data.reduce((acc, item) => {
          acc[item.year] = item.images.map((src, index) => ({
            id: index + 1,
            src: `https://skpbackend.quindltechnologies.com${src}`,
            alt: `Anniversary Image ${index + 1}`,
          }));
          return acc;
        }, {});
        console.log(formattedData);
        setImagesByYear(formattedData);
      } catch (error) {
        console.error("Error fetching anniversary images:", error);
      }
    };

    fetchImages();
  }, []);

  const openPopup = (image, year) => {
    setSelectedImage(image);
    setSelectedYear(year);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setSelectedYear(null);
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="image-container seminar-container">
      {Object.keys(imagesByYear)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((year) => (
        <div key={year} className="image-wrapper">
          <div className="image-text">{year}</div>
          <img
            src={imagesByYear[year][0].src}
            alt={imagesByYear[year][0].alt}
            className="carousel-image"
            onClick={() => openPopup(imagesByYear[year][0].src, year)}
          />
        </div>
      ))}

      {selectedImage && (
        <div className="full-img-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Selected"
              className="popup-main-image"
            />
            <div className="thumbnail-row">
              {imagesByYear[selectedYear].map((img) => (
                <img
                  key={img.id}
                  src={img.src}
                  alt={img.alt}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(img.src)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnniversaryImages;
