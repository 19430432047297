import React from "react";
import Services from "../components/Services";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ServiceHeader from "../components/ServiceHeader";
import hero_img from "../static/img/service_header.jpg";
function ServicePage() {
  return (
    <>
      <Nav />
      <ServiceHeader
        image={hero_img}
        title="Services"
        description="Expert advice, innovative solutions, and comprehensive support tailored to your needs."
      />
      <Services />
      <Footer />
    </>
  );
}
export default ServicePage;
