import React from "react";
import { Link } from "react-router-dom";
import "../static/css/Connect.css";

function Testimonials() {
  return (
    <>
      <div className="connect-container">
        <div className="connect-box">
          <div className="connect-content">
            <h4>Unlock Your Business Potential</h4>
            <p>Start Your Transformation Today.</p>
          </div>
          <div className="connect-vl"></div>
          <div className="connect-button">
            <Link to="/contact">
              <button>Connect With Us</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
