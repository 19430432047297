import React, { useState } from "react";
import "../static/css/Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    mail: "",
    mess: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      <div className="contact-container">
        <div className="contact-box">
          <div className="contact-address">
            <iframe 
              title="SKP & Co. Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.194385061706!2d77.00348122512658!3d11.024037689140181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8598ffc9c58f3%3A0x1538616328cef447!2sMayflower%20signature!5e0!3m2!1sen!2sin!4v1717484414582!5m2!1sen!2sin"
            ></iframe>
            <p>
              CENTRAL OFFICE <br />
              849/2, 2nd floor, Mettupalayam Road,
              <br />
              Poo Market,
              <br />
              Coimbatore – 641002. <br />
              <br />
              Phone : +91 9787597500
              <br />
              Email: info@skpandco.in
            </p>
          </div>
          <div className="vl"></div>
          <div className="contact-connect">
            <h4>Connect with Us</h4>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                name="mail"
                placeholder="Email"
                value={formData.mail}
                onChange={handleChange}
              />
              <input
                type="text"
                name="mess"
                placeholder="Message"
                value={formData.mess}
                onChange={handleChange}
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
