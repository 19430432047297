import React from "react";
import { Link } from "react-router-dom";
import "../static/css/Service.css";
import service1 from "../static/img/service1.png";
import service2 from "../static/img/service2.jpeg";
import service3 from "../static/img/service3.jpeg";
import service4 from "../static/img/service4.jpeg";
import service5 from "../static/img/service5.jpeg";
import service6 from "../static/img/service6.jpeg";
import service7 from "../static/img/service7.jpeg";
import service8 from "../static/img/service8.jpeg";
import service9 from "../static/img/service9.jpeg";
import service10 from "../static/img/service10.jpg";

function Services() {
  const img = [
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    service8,
    service9,
    service10,
  ];
  const heading = [
    "Altenative Dispute Resolution",
    "Corporate Compliance Management",
    "Legal Management and Representation",
    "Transaction Advisory",
    "Due Diligence Audits",
    "Insolvency and Bankruptcy Practice",
    "Intellectual Property Rights",
    "Corporate Regulatory Risk Assessment",
    "Advisory Services",
    "Forex Advisory Services",
  ];

  const discription = [
    "Resolves disputes through mediation or arbitration.",
    "Ensures adherence to laws and regulations.",
    "Provides legal oversight to protect organizational interests.",
    "Guides transactions to mitigate risks.",
    "Assesses risks before significant business transactions.",
    "Manages financial distress and insolvency processes.",
    "Evaluates IP strategies to safeguard assets.",
    "Identifies compliance risks to ensure operational efficiency.",
    "Offers insights to improve operations and support strategy.",
    "Resolves disputes through mediation or arbitration.",
  ];

  return (
    <>
      <div className="services-container">
        <div className="service-box">
          <div className="service-title">
            <h4>Our Services</h4>
          </div>
          <div className="service-list">
            {img.map((img, index) => {
              return (
                <div className="service-element" key={index}>
                  <img src={img} alt="service_image" />
                  <div className="service-element-content">
                    <p>{heading[index]}</p>
                    <span>{discription[index]}</span>
                    <Link to={`/product/${index}`}>
                      <button>Learn More</button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
