import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import myImage from "../static/img/data.jpeg";
import "../static/css/publicationDetail.css";

const POPULAR_PUBLICATIONS_LIMIT = 5;

const PublicationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { publication, allPublications = [] } = location.state || {};
  const [publicationCards, setPublicationCards] = useState([]);

  useEffect(() => {
    if (publication?.categoryId) {
      fetch(`https://skpbackend.quindltechnologies.com/publications/category/${publication.categoryId}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          console.log("Received publications by category:", data);
          const formattedCards = data
            .map((pub) => ({
              id: pub.id,
              title: pub.title,
              imgSrc: pub.image_url || myImage,
              description: pub.description,
              date: new Date(pub.date).toISOString().split("T")[0],
              content: pub.content,
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date));
          setPublicationCards(formattedCards);
        })
        .catch((error) => {
          console.error("Error fetching publications:", error);
          setPublicationCards([]);
        });
    }
  }, [publication?.categoryId]);

  const popularPublications = React.useMemo(() => {
    if (!allPublications?.length) return [];
    const otherPublications = allPublications.filter(
      (pub) => pub.title !== publication.title
    );
    return otherPublications
      .sort(() => Math.random() - 0.5)
      .slice(0, POPULAR_PUBLICATIONS_LIMIT);
  }, [allPublications, publication?.title]);

  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const currentPublicationCards = publicationCards;

  return (
    <>
      <Nav />
      <div className="publication-detail">
        <div className="content-wrapper">
          <div className="main-content">
            <div className="detail-header">
              <div className="header-image">
                <img src={publication.imgSrc} alt={publication.title} />
              </div>
              <div className="header-content">
                <h1 className="main-title">{publication.title}</h1>
                <p className="description">{publication.description}</p>
              </div>
            </div>
            <div className="pop-sidebar-con">
              <div className="pop-sidebar">
                <h3>Popular Publications</h3>
                <div className="popular-publications">
                  {popularPublications.length > 0 ? (
                    popularPublications.map((pub) => (
                      <div
                        key={pub.title}
                        className="popular-pub-item"
                        onClick={() =>
                          navigate("/publication-detail", {
                            state: { publication: pub, allPublications },
                          })
                        }
                      >
                        <img src={pub.imgSrc} alt={pub.title} />
                        <h4>{pub.title}</h4>
                      </div>
                    ))
                  ) : (
                    <p>No related publications available</p>
                  )}
                </div>
              </div>
              <div className="con" style={{ marginBottom: "0%" }}>
                <h2>Latest {publication.title}</h2>
                <div className="con-grid">
                  {currentPublicationCards.map((card) => (
                    <div
                      key={card.id}
                      className="con-item"
                      onClick={() =>
                        navigate(`/publication/${card.id}`, {
                          state: {
                            card,
                            publicationTitle: publication.title,
                            allPublications,
                          },
                        })
                      }
                    >
                      <img
                        className="con-img"
                        src={card.imgSrc}
                        alt={card.title}
                      />
                      <div className="con-content">
                        <div className="con-txt-1">
                          <p>{card.title}</p>
                        </div>
                        <div className="con-txt-2">
                          <p>{truncateText(card.description)}</p>
                        </div>
                        <p className="card-date">{card.date}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicationDetail;
