import React from "react";
import Contact from "../components/Contact";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import contact_img from "../static/img/contact.png";
import ServiceHeader from "../components/ServiceHeader";

function PublicationPage() {
  return (
    <>
      <Nav />
      <ServiceHeader
        image={contact_img}
        title="Contact Us"
        description="Get in touch with us for any inquiries or feedback"
      />
      <Contact />
      <Footer />
    </>
  );
}
export default PublicationPage;
