import React from "react";
import Publications from "../components/Publications";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ServiceHeader from "../components/ServiceHeader";
import publications_img from "../static/img/publications.jpg";

function PublicationPage() {
  return (
    <>
      <Nav />
      <ServiceHeader
        image={publications_img}
        title="Publications"
        description="Stay informed with our regulatory updates, newsletters, and industry insights."
      />
      <Publications />
      <Footer />
    </>
  );
}
export default PublicationPage;
