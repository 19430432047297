import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../static/img/logo.png";
import "../static/css/Nav.css";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="Nav-bar">
        <div className="nav-content">
          <img src={logo} alt="Logo" />
          <span className="menu-icon" onClick={toggleSidebar}>
            &#9776;
          </span>
          <ul>
            <NavLink to="/" end>
              <li>Home</li>
            </NavLink>
            <NavLink to="/team">
              <li>Our Team</li>
            </NavLink>
            <NavLink to="/services">
              <li>Services</li>
            </NavLink>
            <NavLink to="/publications">
              <li>Publications</li>
            </NavLink>
            <NavLink to="/events">
              <li>Events & Calendar</li>
            </NavLink>
            <NavLink to="/gallery">
              <li>Gallery</li>
            </NavLink>
            <NavLink to="/contact">
              <li>Contact Us</li>
            </NavLink>
            <div className="Log-In">
              <Link to="/login">
                <button>Login</button>
              </Link>
            </div>
          </ul>
        </div>
      </div>
      <div className="sidebar" style={{ width: isOpen ? "250px" : "0" }}>
        <span className="closebtn" onClick={toggleSidebar}>
          &times;
        </span>
        <Link to="/login">
          <li className="Log-In">Log In</li>
        </Link>
        <ul>
          <NavLink to="/" end>
            <li>Home</li>
          </NavLink>
          <NavLink to="/team">
            <li>Our Team</li>
          </NavLink>
          <NavLink to="/services">
            <li>Services</li>
          </NavLink>
          <NavLink to="/publications">
            <li>Publications</li>
          </NavLink>
          <NavLink to="/events">
            <li>Events & Calendar</li>
          </NavLink>
          <NavLink to="/gallery">
            <li>Gallery</li>
          </NavLink>
          <NavLink to="/contact">
            <li>Contact Us</li>
          </NavLink>
        </ul>
      </div>
    </>
  );
}

export default Nav;
