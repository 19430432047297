import React, { useEffect, useState } from "react";
import "../static/css/Team.css";
import { Link } from "react-router-dom";
import axios from "axios";

function truncateText(text, limit) {
  const words = text.split(" ");
  return words.length > limit ? words.slice(0, limit).join(" ") + "..." : text;
}

function Team() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://skpbackend.quindltechnologies.com/team")
      .then((response) => {
        setTeamMembers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching team members:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="team-container">
      <div className="team-box">
        <div className="team-title">
          <h4>Meet Our Team</h4>
        </div>
        <div className="team-demo">
          <div className="team-members-grid">
            {teamMembers.map((member, index) => {
              const imageUrl = `https://skpbackend.quindltechnologies.com${member.image.replace(
                /\\/g,
                "/"
              )}`;
              console.log("Member Image URL:", imageUrl);
              return (
                <div className="card text-center" key={member.id}>
                  <img
                    className="rounded-image"
                    src={imageUrl}
                    alt={`${member.name}`}
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      justifySelf: "center",
                      margin: "5px auto",
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{member.name}</h5>
                    <h5 className="card-title">{member.role}</h5>
                    <p className="card-text">
                      {truncateText(member.short_description, 50)}
                    </p>
                    <Link to={`/member/${member.id}`}>
                      <button className="btn-team">Read More</button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
